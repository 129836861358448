import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from "../../modules/user/user.service";
import { timer } from "rxjs/internal/observable/timer";
import { PeriodicRefreshService } from "../../modules/utility/periodic-refresh.service";

@Component({
  selector: 'ae-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private refreshSvc: PeriodicRefreshService
  ) {
  }
  now = new Date();
  private clockSub: Subscription | null = null;
  private isAdminSub: Subscription | null = null;
  private refreshSub: Subscription | null = null;
  canManage = false;
  lastRefreshed!: Date;

  ngOnInit(): void {
    this.isAdminSub = this.userSvc.isAdmin().subscribe(it => {
      this.canManage = it;
    });
    this.clockSub = timer(1, 1000).subscribe(() => {
      this.now = new Date();
    });
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      this.lastRefreshed = refreshed;
    });

    //to make sure at least once after refreshing we initiate currentUser check to determine if user is an admin
    timer(1).subscribe(() => {
      this.userSvc.currentUser().subscribe();
    })
  }

  ngOnDestroy(): void {
    if (this.clockSub) {
      this.clockSub.unsubscribe();
      this.clockSub = null;
    }

    if (this.isAdminSub) {
      this.isAdminSub.unsubscribe();
      this.isAdminSub = null;
    }

    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
  }

  goto(url: string): void {
    this.router.navigate([url]);
  }

}