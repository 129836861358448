import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";
import {map} from "rxjs/operators";

export class StationStatus {
  id: string;
  stationId: string;
  state: string;
  condition: string;
  description: string;
  meta: any;
  created: string;

  constructor(
    id: string,
    stationId: string,
    state: string,
    condition: string,
    description: string,
    meta: any,
    created: string
  ) {
    this.id = id;
    this.stationId = stationId;
    this.state = state;
    this.condition = condition;
    this.description = description;
    this.meta = meta;
    this.created = created;
  }
}

export class StationConfig {
  stationId: string;
  dataSet: string;
  enabled: boolean;

  constructor(stationId: string, dataSet: string, enabled: boolean) {
    this.stationId = stationId
    this.dataSet = dataSet
    this.enabled = enabled
  }
}

@Injectable()
export class MonitoringService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getStationConfig(): Observable<StationConfig[]> {
    const url = this.baseSvc.customerUrl(`conduit/customer/monitoring/device/station-config`);
    return this.http.get(url, this.baseSvc.defaultOptions())
        .pipe(map((response: any) => response.stations as StationConfig[]))
  }

  public getDeviceStatus(dataSets: string[] | null= null): Observable<StationStatus[]> {
    const params: Record<string, string[]> = {};
    if (dataSets && dataSets.length > 0) {
      params["dataSets"] = dataSets;
    }
    const url = this.baseSvc.customerUrl(`conduit/customer/monitoring/device/status/list`);
    const req: Observable<any> = this.http
        .get(url, this.baseSvc.defaultOptions({params: params}))
        .pipe(map((r) => (r as any)["conduit-device-status-list"]));

    return req;
  }
}
