import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RecordApiService } from '../../modules/record/record-api.service';
import { AuthenticationRequest } from '../../modules/record/authenticationRequest';

@Component({
  selector: 'ae-auth',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  protected authenticationId!: string;
  @Input() fullView = true;

  @Input() set authId(value: string) {
    this.authenticationId = value;
    this.getDetail();
  }

  @Input() set authenticationRecord(value: AuthenticationRequest) {
    this.authenticationRequest = value;
    this.getPreview();
  }

  private authStream!: Subscription;
  public authenticationRequest!: AuthenticationRequest;
  public authPreview!: string;

  constructor(private recordApi: RecordApiService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!this.authenticationRequest) {
      this.getDetail();
    }
  }

  getDetail(): void {
    if (!this.authenticationId) {
      this.authenticationId = this.route.snapshot.paramMap.get('authenticationId') as string;
    }

    if (this.authStream) {
      this.authStream.unsubscribe();
    }

    this.authStream = this.recordApi.getAuthentication(this.authenticationId).subscribe(auth => {
      this.authenticationRequest = auth;
      this.authPreview = this.recordApi.computePreviewUrl(this.authenticationRequest?.displayImageId as unknown as string
        || this.authenticationRequest?.authentication?.previewImageId as unknown as string) as string;
    });
  }

  getPreview(): void {
    if (this.authenticationRequest) {
      this.authPreview = this.recordApi.computePreviewUrl(this.authenticationRequest?.displayImageId as unknown as string
        || this.authenticationRequest?.authentication?.previewImageId as unknown as string) as string;
    }
  }
}
