import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { PhysicalRegistration } from './physicalRegistration';
import { map } from 'rxjs/operators';
import { AuthenticationRequest } from './authenticationRequest';
import { DataSetContext } from "./context-models";
import { ConfigService } from "../base/config.service";
import { AppConfig } from "../base/app-config";

export class Page {
  pageNumber: number;
  pageSize: number;

  constructor(pageNumber: number, pageSize: number) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}

export class SearchCriteria {
  startDate: Date;
  endDate: Date | null;
  stationList: string[];
  dataSets: string[];
  status: string | null;
  itemId: string | null;
  contextId: string | null;
  page: Page | null;

  constructor(startDate: Date, endDate: Date | null = null, stationList: string[] = [], datasets: string[] = [],
    status: string | null = null, itemId: string | null = null, contextId: string | null = null, page: Page | null = null) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.stationList = stationList;
    this.dataSets = datasets;
    this.status = status;
    this.itemId = itemId;
    this.contextId = contextId;
    this.page = page;
  }
}

export class Counts {
  count: number;

  constructor(counts: number) {
    this.count = counts;
  }
}

export class CountsByDay {
  day: string;
  count: number;

  constructor(date: string, count: number) {
    this.day = date;
    this.count = count;
  }
}

@Injectable()
export class RecordApiService {

  constructor(private baseSvc: BaseService, private configSvc: ConfigService<AppConfig>, private http: HttpClient) {
  }

  public dayInMillis = (1000 * 60 * 60 * 24);

  public getDataContexts(): Observable<DataSetContext[]> {

    const url = this.baseSvc.customerUrl(`conduit/customer/ctx-info/data-set-context/list`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((ds: any) => ds as DataSetContext));

    return req;
  }

  public computePreviewUrl(previewImageId: string): string | null {
    return previewImageId ? (this.baseSvc.customerUrl(`assets/${previewImageId}`)) : null;
  }

  public disableRegistration(physicalId: string): Observable<void> {
    const url = this.baseSvc.customerUrl(`conduit/customer/registration-record/disable-multi`);
    const payload: any = {
      physicalId: physicalId
    };

    const formData = new FormData();
    formData.append(
      // `${this.configSvc.getConfig().project}-disable-registration`,
      `demo-disable-registration`,
      new Blob([JSON.stringify(payload)], { type: 'application/json' }));
    const req: Observable<any> = this.http
      .put(url, formData, { headers: this.baseSvc.multipartHeaders() })
      .pipe(
        map((registration: any) => registration as PhysicalRegistration)
      );

    return req;
  }

  public enableRegistration(physicalId: string): Observable<void> {
    const url = this.baseSvc.customerUrl(`conduit/customer/registration-record/enable-multi`);
    const payload: any = {
      physicalId: physicalId
    };

    const formData = new FormData();
    formData.append(
      // `${this.configSvc.getConfig().project}-enable-registration`,
      `demo-enable-registration`,
      new Blob([JSON.stringify(payload)], { type: 'application/json' }));
    const req: Observable<any> = this.http
      .put(url, formData, { headers: this.baseSvc.multipartHeaders() })
      .pipe(
        map((registration: any) => registration as PhysicalRegistration)
      );

    return req;
  }


  public getRegistration(physicalId: string): Observable<PhysicalRegistration> {

    const url = this.baseSvc.projectUrl(`registrations/${physicalId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((registration: any) => registration as PhysicalRegistration)
      );

    return req;
  }

  public searchRegistrations(search: SearchCriteria): Observable<PhysicalRegistration[]> {

    const url = this.baseSvc.projectUrl(`registrations/filter`);
    let searchParams: any = {};
    if (search.page) {
      searchParams['page'] = search.page.pageNumber;
      searchParams['pageSize'] = search.page.pageSize;
    }
    if (search.itemId && search.itemId.trim() !== '') {
      searchParams = {itemId: search.itemId};
    }

    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.stationList && search.stationList.length > 0) {
      searchParams.stationIds = search.stationList;
    }
    if (search.dataSets && search.dataSets.length > 0) {
      if (!search.dataSets.some(d => d == '*')) {
        searchParams.dataSets = search.dataSets;
      }
    }
    if (search.contextId && search.contextId.length > 0) {
      searchParams.contextId = search.contextId;
    }
    if (search.status) {
      searchParams.status = search.status;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((registration: any) => registration as PhysicalRegistration[])
      );

    return req;
  }

  public registrationCountForFilter(search: SearchCriteria): Observable<Counts> {

    const url = this.baseSvc.projectUrl(`registrations/count`);
    let searchParams: any = {};
    if (search.page) {
      searchParams['page'] = search.page.pageNumber + 1;
      searchParams['pageSize'] = search.page.pageSize;
    }

    if (search.itemId && search.itemId.trim() !== '') {
      searchParams = {itemId: search.itemId};
    }

    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.stationList && search.stationList.length > 0) {
      searchParams.stationIds = search.stationList;
    }
    if (search.dataSets && search.dataSets.length > 0) {
      if (!search.dataSets.some(d => d == '*')) {
        searchParams.dataSets = search.dataSets;
      }
    }
    if (search.contextId && search.contextId.length > 0) {
      searchParams.contextId = search.contextId;
    }
    if (search.status) {
      searchParams.status = search.status;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((registration: any) => registration as PhysicalRegistration[])
      );

    return req;
  }

  public registrationCountsByDay(dataSets: string[], startDate: Date, endDate: Date | null = null): Observable<CountsByDay[]> {

    const url = this.baseSvc.projectUrl(`registrations/count-by-day`);
    const searchParams: any = {};
    if (startDate) {
      searchParams.start = startDate.toISOString();
    }
    if (endDate) {
      searchParams.end = endDate.toISOString();
    }
    // * is the same as ALL which is done by not specifying any
    if (!dataSets.some(d => d == '*')) {
      searchParams.dataSets = dataSets;
    }
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((dayCnt: any) => dayCnt as CountsByDay)
      );

    return req;
  }

  public getAuthentication(authenticationId: string): Observable<AuthenticationRequest> {

    const url = this.baseSvc.projectUrl(`authentications/${authenticationId}`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((authentication: any) => authentication as AuthenticationRequest)
      );

    return req;
  }

  public searchAuthentications(search: SearchCriteria): Observable<AuthenticationRequest[]> {

    const url = this.baseSvc.projectUrl(`authentications/filter`);
    let searchParams: any = {};
    if (search.page) {
      searchParams['page'] = search.page.pageNumber;
      searchParams['pageSize'] = search.page.pageSize;
    }
    if (search.itemId && search.itemId.trim() !== '') {
      searchParams = {itemId: search.itemId};
    }

    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.stationList && search.stationList.length > 0) {
      searchParams.stationIds = search.stationList;
    }
    if (search.dataSets && search.dataSets.length > 0) {
      if (!search.dataSets.some(d => d == '*')) {
        searchParams.dataSets = search.dataSets;
      }
    }
    if (search.contextId && search.contextId.length > 0) {
      searchParams.contextId = search.contextId;
    }
    if (search.status) {
      searchParams.status = search.status;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((auths: any) => auths as AuthenticationRequest[])
      );

    return req;
  }

  public authenticationCountForFilter(search: SearchCriteria): Observable<Counts> {

    const url = this.baseSvc.projectUrl(`authentications/count`);

    let searchParams: any = {};
    if (search.startDate) {
      searchParams.start = search.startDate.toISOString();
    }
    if (search.endDate) {
      searchParams.end = search.endDate.toISOString();
    }
    if (search.stationList && search.stationList.length > 0) {
      searchParams.stationIds = search.stationList[0];
    }
    if (search.dataSets && search.dataSets.length > 0) {
      // * is the same as ALL which is done by not specifying any
      if (!search.dataSets.some(d => d == '*')) {
        searchParams.dataSets = search.dataSets;
      }
    }
    if (search.contextId && search.contextId.length > 0) {
      searchParams.contextId = search.contextId;
    }
    if (search.itemId && search.itemId.trim() !== '') {
      // for LPN search we can ignore dates since LPN is unique
      searchParams = { itemId: search.itemId };
    }
    if (search.status) {
      searchParams.status = search.status;
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((counts: any) => counts as Counts)
      );

    return req;
  }

  public authenticationCountsByDay(dataSets: string[], startDate: Date, endDate: Date | null = null): Observable<CountsByDay[]> {

    const url = this.baseSvc.projectUrl(`authentications/count-by-day`);

    const searchParams: any = {};
    if (startDate) {
      searchParams.start = startDate.toISOString();
    }
    if (endDate) {
      searchParams.end = endDate.toISOString();
    }
    if (dataSets && dataSets.length > 0) {
      // * is the same as ALL which is done by not specifying any
      if (!dataSets.some(d => d == '*')) {
        searchParams.dataSets = dataSets;
      }
    }

    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions({ params: searchParams }))
      .pipe(
        map((dayCnt: any) => dayCnt as CountsByDay)
      );

    return req;
  }
}
