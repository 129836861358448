<div>
  <h3>Today's Non-Matched Authentications</h3>
  <h4 *ngIf="!(nonMatches && nonMatches.length > 0)">None Today</h4>
  <table *ngIf="nonMatches && nonMatches.length > 0" class="table table-sm table-hover table-striped">
    <thead>
    <tr>
      <th class="preview-label">Created</th>
      <th class="preview-label">Station</th>
      <th class="preview-label">Label</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let authentication of nonMatches" class="link-active" (click)="gotoAuth(authentication.authenticationId)">
      <td matTooltip="click to view">{{authentication.created | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
      <td matTooltip="click to view">{{authentication.stationId}}</td>
      <td matTooltip="click to view">{{authentication?.itemId || 'N/A'}}</td>
    </tr>
    </tbody>
  </table>
</div>

