import {HttpClient} from "@angular/common/http";
import {Pipe, PipeTransform} from "@angular/core";
import {BaseService} from '../base/base.service';

@Pipe({
  name: 'previewImage'
})
export class PreviewImagePipe implements PipeTransform {

  constructor(
      private http: HttpClient,
      private apiBase: BaseService
  ) {
  }

  async transform(src: string): Promise<string> {
    if (src == null) {
      return "";
    }

    const headers = this.apiBase.bareAuthHeaders();
    //console.log("src image", src);
    const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob as Blob);
    });
  }
}
