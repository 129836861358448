export class TokenInfo {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  token_type: string;

  constructor(
    access_token: string,
    expires_in: number,
    refresh_token: string,
    refresh_expires_in: number,
    token_type: string,
  ) {
    this.access_token = access_token
    this.expires_in = expires_in
    this.refresh_token = refresh_token
    this.refresh_expires_in = refresh_expires_in
    this.token_type = token_type
  }
}

export class AccessToken {
  token: string;
  tokenExpires: Date;
  refreshToken: string;
  refreshExpires: Date;
  tokenType: string;

  constructor(token: string, tokenExpires: Date, refreshToken: string, refreshExpires: Date, tokenType: string) {
    this.token = token;
    this.tokenExpires = tokenExpires;
    this.refreshToken = refreshToken;
    this.refreshExpires = refreshExpires;
    this.tokenType = tokenType;
  }

  public isTokenExpired(): boolean {
    let expired = true;
    try {
      const expires = (this.tokenExpires.getTime() || this.expiredOrMissingTokenDate().getTime());
      const now = Date.now();
      expired = (expires < now);
    } catch (e) {
      console.log("error checking token expire", e);
    }
    return expired;
  }

  static fromTokenInfo(tokenInfo: TokenInfo) {
    return new AccessToken(
        tokenInfo.access_token, this.calculateExpireDate(tokenInfo.expires_in),
        tokenInfo.refresh_token, this.calculateExpireDate(tokenInfo.refresh_expires_in)
        , tokenInfo.token_type
    );
  }

  static calculateExpireDate(expiresInSeconds: number): Date {
    return new Date(Date.now() + (expiresInSeconds * 1000));
  }

  private expiredOrMissingTokenDate(): Date {
    return new Date(0);
  }

}

