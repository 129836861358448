import { Component, OnInit } from '@angular/core';
import { CoreService, LookupLists } from "../../modules/core.service";
import { SearchCriteria } from "../../modules/record/record-api.service";

@Component({
  selector: 'app-dash',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  lookups!: LookupLists;
  selectedDataSets: string[] = [];

  constructor(private coreSvc: CoreService) { }

  ngOnInit(): void {
    this.coreSvc.getLookupLists().subscribe(lookups => {
      if (lookups?.dataSets.some(ds => ds == "*")) {
        this.selectedDataSets = ["*"];
      }
      this.lookups = lookups;
    });
  }

  public searchHandler(searchCriteria: SearchCriteria): void {
    this.selectedDataSets = searchCriteria?.dataSets;
  }

}
