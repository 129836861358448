<div *ngIf="showHeader && physicalId" class="container-fluid record-header">
  <mat-card>
    <div class="center-title">
      <mat-card-header>
        <mat-card-title>
          Registration: {{ physicalRegistration?.physicalIdentifier?.itemId }}
        </mat-card-title>
      </mat-card-header>
    </div>
  </mat-card>
</div>
<div class="container-fluid" *ngIf="physicalId">
  <div class="row row-padded">
    <div class="col-md-6">
      <ae-reg [fullView]="false" [registrationRecord]="physicalRegistration?.registration"></ae-reg>
    </div>
    <div class="col-md-6">
      <mat-card class="record-card">
        <table class="table table-sm table-hover table-striped">
          <thead>
            <tr style="border:none">
              <th colspan="2" class="record-title">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Item ID:</td>
              <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.itemId }}</td>
            </tr>
            <tr>
              <td>Context ID:</td>
              <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.contextId }}</td>
            </tr>
            <tr>
              <td>Context Selector:</td>
              <td class="detail-label">
                <pre>{{ physicalRegistration?.registration?.contextSelector | json }}</pre>
              </td>
            </tr>
            <tr>
              <td>Data:</td>
              <td class="detail-label">
                <pre>{{ physicalRegistration?.physicalIdentifier?.data | json }}</pre>
              </td>
            </tr>
            <tr *ngIf="canDisablePhysicalIdentifier()">
              <td colspan="2" class="td-center"><button (click)="disable()" mat-stroked-button
                  color="warn">Disable</button></td>
            </tr>
            <tr *ngIf="canEnablePhysicalIdentifier()">
              <td colspan="2" class="td-center"><button (click)="enable()" mat-stroked-button
                  color="warn">Enable</button></td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
  </div>
</div>
<div class=container-fluid *ngIf="!physicalId">
  <mat-card>
    <mat-card-content>
      Missing
    </mat-card-content>
  </mat-card></div>