import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {AuthenticationComponent} from '../components/authentication/authentication.component';
import {RegistrationComponent} from '../components/registration/registration.component';
import {LoginComponent} from './user/login/login.component';
import {UserMgtComponent} from './user/user-mgt/user-mgt.component';
import {
  AuthenticationListComponent
} from '../components/authentication-list/authentication-list.component';
import {
  RegistrationListComponent
} from '../components/registration-list/registration-list.component';
import {PhysicalRegistrationComponent} from '../components/physical-registration/physical-registration.component';
import {BaseService} from "./base/base.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private baseSvc: BaseService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('auth-guard, token expired:', this.authService.isTokenExpired(), route, state);
    if (!this.baseSvc.isTokenExpired()) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}});
    return false;
  }
}

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LoginComponent
  },
  {
    path: 'user-mgt',
    component: UserMgtComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'authentications',
    component: AuthenticationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authentication/:authenticationId',
    component: AuthenticationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registrations',
    component: RegistrationListComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'registrations/physical/:physicalId',
    component: PhysicalRegistrationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registrations/:registrationId',
    component: RegistrationComponent,
    canActivate: [AuthGuard]

  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}

