import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Role, UserInfo } from "./user-info";
import { throwError } from "rxjs";
import { AccessToken, TokenInfo } from "../base/access-token";

@Injectable()
export class UserService implements OnInit {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  ngOnInit() {
    console.log("config", this.baseSvc.appConfig())
  }

  private readonly currentUserSubject: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);
  private readonly isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isAdmin(): Observable<boolean> {
    return this.isAdminSubject.asObservable();
  }

  public currentUser(): Observable<UserInfo> {
    if (!this.currentUserSubject.value) {
      return this.getUser().pipe(switchMap(() => this.currentUserSubject.asObservable() as BehaviorSubject<UserInfo>));
    } else {
      return (this.currentUserSubject as BehaviorSubject<UserInfo>).asObservable();
    }
  }

  public logout(): void {
    this.baseSvc.clearAuthTokens();
    this.currentUserSubject.next(null);
    this.isAdminSubject.next(false);
  }

  public login(user: string, pwd: string): Observable<any> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.apiUrl(`sys/auth/login`);
    const payload = {
      realm: config.realm,
      clientId: config.clientId,
      username: user,
      password: pwd
    };

    const req: Observable<any> = this.http.post(url, payload)
      .pipe(
        tap((response: any) => this.processLoginResponse(response))
      );
    return req;
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.baseSvc.getAccessToken()?.refreshToken;
    if (!refreshToken) {
      const error = {
        status: 401,
        error: {
          message: 'Expired Credentials, Requires Login',
          userMessage: 'Expired Credentials, Requires Login'
        }
      }
      return throwError(() => error);
    }
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.apiUrl(`sys/auth/refresh`);
    const payload = {
      "realm": config.realm,
      "clientId": config.clientId,
      "refreshToken": this.baseSvc.getAccessToken()?.refreshToken
    };
    const req: Observable<any> = this.http
      .post(url, payload)
      .pipe(
        tap((response: any) => this.processLoginResponse(response))
      );
    return req;
  }

  public listUsers(): Observable<UserInfo[]> {
    const url = this.baseSvc.customerUrl(`profile/user/list`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((auths: any) => auths as UserInfo[])
      );
    return req;
  }

  public getUser(): Observable<UserInfo> {
    const url = this.baseSvc.customerUrl(`profile/user`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => {
          return this.mapUser(response);
        }),
        tap((user) => {
          return this.broadcastUserUpdate(user);
        })
      );
    return req;
  }

  public createUser(user: UserInfo, pwd: string): Observable<UserInfo> {
    const url = this.baseSvc.customerUrl(`profile/user`);
    const req: Observable<any> = this.http
      .post(url, user, this.baseSvc.defaultOptions())
      .pipe(
        catchError(error => throwError(() => error)),
        switchMap((response: any) => {
          return this.setPassword(response.userId, pwd).pipe(
            map((response: any) => {
              this.mapUser(response)
            })
          )
        })
      );
    return req;
  }

  public updateUser(userInfo: UserInfo): Observable<string> {
    const url = this.baseSvc.customerUrl(`profile/user`);
    const req: Observable<any> = this.http
      .put(url, userInfo, this.baseSvc.defaultOptions())
      .pipe(
        map((response: any) => this.mapUser(response))
      );
    return req;
  }

  public setPassword(userId: string, newPassword: string): Observable<UserInfo> {
    const config = this.baseSvc.appConfig();
    const url = this.baseSvc.customerUrl(`profile/user/reset-password`);

    let body = { userId: userId, password: newPassword };
    const req: Observable<any> = this.http
      .put(url, body, this.baseSvc.defaultOptions())
      .pipe(
        map((result: any) => userId)
      );
    return req;
  }

  public deleteUser(userId: string): Observable<void> {
    const url = this.baseSvc.customerUrl(`profile/user/${userId}`);
    const req: Observable<any> = this.http
      .delete(url, this.baseSvc.defaultOptions());
    return req;
  }

  public listRoles(): Observable<Role[]> {
    const url = this.baseSvc.customerUrl(`profile/settings/all-roles`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((roles: any) => roles as Role[])
      );
    return req;
  }

  public listDataSets(): Observable<string[]> {
    const url = this.baseSvc.customerUrl(`profile/settings/all-data-sets`);
    const req: Observable<any> = this.http
      .get(url, this.baseSvc.defaultOptions())
      .pipe(
        map((roles: any) => roles as Role[])
      );
    return req;
  }

  private mapUser(raw: UserInfo): UserInfo {
    return raw;
  }

  private mapToken(raw: any): TokenInfo {
    return raw.tokenInfo as TokenInfo;
  }

  private processLoginResponse(response: any) {
    const accessToken = AccessToken.fromTokenInfo(this.mapToken(response));
    this.baseSvc.setToken(accessToken);
    const userInfo = this.mapUser(response.userInfo);
    this.broadcastUserUpdate(userInfo)
  }

  private broadcastUserUpdate(newUser: UserInfo): void {
    const currentUser = this.currentUserSubject.value;
    if (currentUser !== newUser) {
      this.currentUserSubject.next(newUser);
      this.broadcastIsAdmin();
    }
  }

  private broadcastIsAdmin(): void {
    const adminRoles = this.baseSvc.appConfig().adminRoleNames;
    this.isAdminSubject
      .next((this.currentUserSubject as BehaviorSubject<UserInfo>).value.roles.some(userRole => adminRoles.some(adminRole => adminRole === userRole.name)));
  }

}
